import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import config from 'config';

import { SERVICE_NAMES } from 'constants/index';
import { FastCityWebsocketService } from 'services/websocket/FastCityWebsocketService';
import { loggerProvider } from 'services/logger/logger';

import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import { WSConnectionClose, WSConnectionOpen, WSConnectionOnError } from './actions';

const isProduction = process.env.NODE_ENV === 'production';

// ==============================|| REDUX - MAIN STORE ||============================== //

const fastCityApiService = new FastCityWebsocketService({
    url: config.apiEndpoint,
    loggerProvider
});

const services = {
    [SERVICE_NAMES.FAST_CITY_API]: fastCityApiService,
    [SERVICE_NAMES.LOGGER]: loggerProvider
};

const sagaMiddleware = createSagaMiddleware({ context: services });

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: services
            },
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                    WSConnectionClose,
                    WSConnectionOpen,
                    WSConnectionOnError
                ]
            }
        }).concat(sagaMiddleware)
    // devTools: !isProduction
});

if (!isProduction && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
}

store.runSaga = (saga) => sagaMiddleware.run(saga);
store.runSaga(rootSaga);

const persister = persistStore(store);
export { store, persister };
