import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// import { LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import { getCookie, deCodeCookie, COOKIE_NAME } from 'constants/cookie';
import { useAuth } from 'hooks/useAuth';
// import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import { useJsApiLoader } from '@react-google-maps/api';

// ==============================|| APP ||============================== //

const App = () => {
    const { login } = useAuth();
    useEffect(() => {
        if (document.cookie.includes(COOKIE_NAME)) {
            const token = getCookie(COOKIE_NAME);
            login({ token: deCodeCookie(token?.split('')) });
        }

        return;
    }, [login]);

    const customization = useSelector((state) => state.customization);

    const API_KEY = 'AIzaSyCWKH7KXKGPTwx2Nh_e-WB1NpSLX7Bfr6g';

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: API_KEY
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                {/* RTL layout */}
                {/* <RTLLayout> */}
                <Locales>
                    {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
                    <NavigationScroll>
                        {isLoaded && (
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        )}
                    </NavigationScroll>
                    {/*</LocalizationProvider>*/}
                </Locales>
                {/* </RTLLayout> */}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
