import { useSelector } from 'react-redux';
import * as selectors from 'store/selectors';

export const useFilteredCourierList = () => {
    const list = useSelector(selectors.couriersSelectors.filteredCourierList);
    const listWithGlobalAdmin = useSelector(selectors.couriersSelectors.filteredCompanyCourierList);
    const listStatus = useSelector(selectors.couriersSelectors.listStatus);

    return {
        list,
        listWithGlobalAdmin,
        listStatus
    };
};
