import { pick, values } from 'lodash';
import { RequestStatus } from 'utils/RequestStatus';

import { companiesAdapter } from './reducers/companiesReducer';
import { subCompaniesAdapter } from './reducers/subCompaniesReducer';
import { adminsAdapter } from './reducers/adminsReducer';
import { couriersAdapter } from './reducers/couriersReducer';
import { lastLocationsAdapter } from './reducers/lastLocationsReducer';
import { ordersAdapter } from './reducers/ordersReducer';

// Companies
const basicCompaniesSelectors = companiesAdapter.getSelectors((state) => state.companies.list);
export const companiesSelectors = {
    ...basicCompaniesSelectors,
    listStatus: (state) => new RequestStatus(state.companies.listStatus),
    createStatus: (state) => new RequestStatus(state.companies.createStatus),
    updateStatus: (state) => new RequestStatus(state.companies.updateStatus),
    deleteStatus: (state) => new RequestStatus(state.companies.deleteStatus)
};

// Admins
const basicAdminsSelectors = adminsAdapter.getSelectors((state) => state.admins.list);
const selectAdminsByCompanyId = (state, companyId) => {
    const admins = basicAdminsSelectors.selectAll(state);
    return admins.filter((a) => a.companyId === companyId);
};
export const adminsSelectors = {
    ...basicAdminsSelectors,
    selectAdminsByCompanyId,
    listStatus: (state) => new RequestStatus(state.admins.listStatus),
    createStatus: (state) => new RequestStatus(state.admins.createStatus),
    updateStatus: (state) => new RequestStatus(state.admins.updateStatus),
    deleteStatus: (state) => new RequestStatus(state.admins.deleteStatus)
};

// Ext Sub Companies
const basicSubCompaniesSelectors = subCompaniesAdapter.getSelectors((state) => state.subCompanies.list);
export const subCompaniesSelectors = {
    ...basicSubCompaniesSelectors,
    entitiesByCompanyId: (state, companyId) => {
        const all = basicSubCompaniesSelectors.selectAll(state) || [];
        return all.filter((sc) => sc.companyId === companyId);
    },
    listStatus: (state) => new RequestStatus(state.subCompanies.listStatus),
    updateStatus: (state) => new RequestStatus(state.subCompanies.updateStatus),
    createStatus: (state) => new RequestStatus(state.subCompanies.createStatus),
    deleteStatus: (state) => new RequestStatus(state.subCompanies.deleteStatus)
};

export const couriersFilters = (state) => state.couriersFilters;

// Couriers
const basicCouriersSelectors = couriersAdapter.getSelectors((state) => state.couriers.list);

const basicCouriersFilter = (c) => c && !c.isDeleted;
const getBySubCompanyFilter = (state) => {
    const { companyId, externalSubCompanyId } = couriersFilters(state);
    return (c) =>
        c.companyId === companyId && Array.isArray(c.externalSubCompanyId)
            ? c.externalSubCompanyId.includes(externalSubCompanyId)
            : c.externalSubCompanyId === externalSubCompanyId;
};
const getByConnectedFilter = (state) => {
    const { connectedOnly } = couriersFilters(state);
    return (c) => !connectedOnly || c.isConnected;
};

const filteredByCompanyAndExternalSubCompanyOnly = (state) => {
    const couriers = basicCouriersSelectors.selectAll(state);
    const bySubCompanyFilter = getBySubCompanyFilter(state);

    return couriers.filter((c) => basicCouriersFilter(c) && bySubCompanyFilter(c));
};

const filteredByCompanyAndExternalSubCompanyAndConnected = (state) => {
    const couriers = filteredByCompanyAndExternalSubCompanyOnly(state);
    const byConnectedFilter = getByConnectedFilter(state);

    return couriers.filter(byConnectedFilter);
};

const filteredCourierList = (state) => {
    const { courierId } = couriersFilters(state);
    const byConnectedFilter = getByConnectedFilter(state);

    return courierId
        ? [basicCouriersSelectors.selectById(state, courierId)].filter(basicCouriersFilter).filter(byConnectedFilter)
        : filteredByCompanyAndExternalSubCompanyAndConnected(state);
};

const filteredCompanyCourierList = (state) => {
    const couriers = basicCouriersSelectors.selectAll(state);
    const byConnectedFilter = getByConnectedFilter(state);

    return couriers.filter((c) => basicCouriersFilter(c) && byConnectedFilter(c));
};

export const couriersSelectors = {
    ...basicCouriersSelectors,
    listStatus: (state) => new RequestStatus(state.couriers.listStatus),
    createStatus: (state) => new RequestStatus(state.couriers.createStatus),
    updateStatus: (state) => new RequestStatus(state.couriers.updateStatus),
    filteredCompanyCourierList,
    couriersFilters,
    filteredCourierList,
    filteredByCompanyAndExternalSubCompanyAndConnected,
    filteredByCompanyAndExternalSubCompanyOnly
};

// LastLocations
const basicLastLocationsSelectors = lastLocationsAdapter.getSelectors((state) => state.lastLocations);
const selectByCourierIds = (state, couriersIds = []) => {
    const entities = basicLastLocationsSelectors.selectEntities(state);
    return values(pick(entities, couriersIds));
};

export const lastLocationsSelectors = {
    ...basicLastLocationsSelectors,
    selectByCourierIds
};

// Orders
const basicOrdersSelectors = ordersAdapter.getSelectors((state) => state.orders.list);

const ordersPagination = (state) => state.orders.pagination;

const currentPageList = (state) => {
    const { page, pages } = ordersPagination(state);

    const orders = basicOrdersSelectors.selectAll(state);
    const ordersIds = pages[page] || [];

    const currentPageOrderIds = new Set(ordersIds);

    return orders.filter((o) => currentPageOrderIds.has(o.orderId));
};

export const ordersSelectors = {
    ...basicOrdersSelectors,
    listStatus: (state) => new RequestStatus(state.orders.listStatus),
    pagination: ordersPagination,
    currentPageList
};

// DeliveryStat
export const deliveryStatSelectors = {
    dayList: (state) => state.deliveryStat.dayList,
    dayListStatus: (state) => new RequestStatus(state.deliveryStat.dayListStatus)
};

// DeliveryStat
export const subCompanyZoneSelectors = {
    polygonList: (state) => state.subCompanyZone.polygon,
    subCompanyZoneStatus: (state) => new RequestStatus(state.subCompanyZone.subCompanyZoneStatus),
    createSubCompanyZoneStatus: (state) => new RequestStatus(state.subCompanyZone.createSubCompanyZone),
    updateSubCompanyZoneStatus: (state) => new RequestStatus(state.subCompanyZone.updateSubCompanyZoneUsingKmlStatus),
    deleteSubCompanyZoneStatus: (state) => new RequestStatus(state.subCompanyZone.deleteZoneStatus)
};

export const externalAuthTokenSelectors = {
    externalAuthToken: (state) => state.externalAuthToken.externalAuthToken,
    externalAuthTokenStatus: (state) => new RequestStatus(state.externalAuthToken.externalAuthTokenStatus),
    createStatus: (state) => new RequestStatus(state.externalAuthToken.createStatus),
    updateStatus: (state) => new RequestStatus(state.externalAuthToken.updateStatus),
    deleteStatus: (state) => new RequestStatus(state.externalAuthToken.deleteStatus)
};
