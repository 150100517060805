// third-party
import { FormattedMessage } from 'react-intl';

// project import
import { routes } from 'constants/index';

// assets
import { IconBuildingSkyscraper } from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    type: 'group',
    children: [
        {
            id: 'companies',
            title: <FormattedMessage id='companies' />,
            type: 'item',
            url: routes.adminCompanies,
            icon: IconBuildingSkyscraper,
            breadcrumbs: false
        }
    ]
};

export default admin;
