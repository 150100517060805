import { createReducer } from '@reduxjs/toolkit';

// action - state management
import * as actions from 'store/actions';
import { CONNECTION_STATUS, REQUEST_STATUS } from 'constants/index';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    connectionStatus: CONNECTION_STATUS.CLOSED,
    loginStatus: REQUEST_STATUS.INIT,
    loginFailureReason: null,
    resetPasswordStatus: REQUEST_STATUS.INIT,
    resetPasswordFailureReason: null,
    activateStatus: REQUEST_STATUS.INIT,
    activateFailureReason: null,
    isLoggedIn: false,
    user: null
};

export const accountReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.login, (state) => {
            state.isLoggedIn = false;
            state.loginStatus = REQUEST_STATUS.PENDING;
            state.user = null;
        })
        .addCase(actions.logout, (state) => {
            state.isLoggedIn = false;
            state.loginStatus = REQUEST_STATUS.INIT;
            state.user = null;
        })
        .addCase(actions.loginSuccess, (state) => {
            state.isLoggedIn = true;
            state.loginStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.loginFailure, (state, action) => {
            state.isLoggedIn = false;
            state.loginFailureReason = action.payload || null;
            state.loginStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.loginReset, (state) => {
            state.isLoggedIn = false;
            state.loginFailureReason = null;
            state.loginStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.WSConnectionOpen, (state) => {
            state.connectionStatus = CONNECTION_STATUS.OPENED;
        })
        .addCase(actions.WSConnectionClose, (state) => {
            state.connectionStatus = CONNECTION_STATUS.CLOSED;
            state.loginStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.getCurrentAdminSuccess, (state, action) => {
            state.user = action.payload;
        })
        .addCase(actions.gotAdminData, (state, action) => {
            if (state.user?.login === action.payload?.login) {
                state.user = { ...state.user, ...action.payload };
            }
        })
        .addCase(actions.resetPassword, (state) => {
            state.resetPasswordStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.resetPasswordSuccess, (state) => {
            state.resetPasswordStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.resetPasswordFailure, (state, action) => {
            state.resetPasswordFailureReason = action.payload || null;
            state.resetPasswordStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.resetPasswordReset, (state) => {
            state.resetPasswordStatus = REQUEST_STATUS.INIT;
            state.resetPasswordFailureReason = null;
        })
        .addCase(actions.activate, (state) => {
            state.activateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.activateSuccess, (state) => {
            state.activateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.activateFailure, (state, action) => {
            state.activateFailureReason = action.payload || null;
            state.activateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.activateReset, (state) => {
            state.activateStatus = REQUEST_STATUS.INIT;
            state.activateFailureReason = null;
        })
);
