import { fastcity } from 'protocol/lib/bundle.pb';
import { SERVICE_NAMES } from 'constants/index';
import { WebsocketService } from './WebsocketService';
import { getProtocolSemanticVersion } from './helpers/getProtocolSemanticVersion';

export class FastCityWebsocketService extends WebsocketService {
    constructor(customOptions = {}) {
        super({
            url: '',
            name: SERVICE_NAMES.FAST_CITY_API,
            protocolVersion: `fastcity-admin-${getProtocolSemanticVersion(fastcity.admin)}`,
            protocolEncoder: fastcity.admin.ClientMessage,
            protocolDecoder: fastcity.admin.ServerMessage,
            ...customOptions
        });

        if (!FastCityWebsocketService.instance) {
            FastCityWebsocketService.instance = this;
        }

        return FastCityWebsocketService.instance;
    }
}
