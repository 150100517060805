import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { routes } from 'constants/index';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/AuthLogin')));

const AuthPasswordReset = Loadable(lazy(() => import('views/pages/AuthPasswordReset.js')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: routes.root,
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: routes.login,
            element: <AuthLogin />
        },
        {
            path: routes.passwordReset,
            element: <AuthPasswordReset />
        },
        {
            path: '*',
            element: <Navigate to={routes.root} />
        }
    ]
};

export default LoginRoutes;
