import { useSelector } from 'react-redux';
import * as selectors from 'store/selectors';

export const useCurrentSubCompany = () => {
    const filters = useSelector(selectors.couriersFilters);

    const subCompanies = useSelector((state) =>
        selectors.subCompaniesSelectors.entitiesByCompanyId(state, filters.companyId || '')
    );
    return subCompanies?.find((s) => s.externalSubCompanyId === filters.externalSubCompanyId);
};
