import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    polygon: [],
    subCompanyZoneStatus: REQUEST_STATUS.INIT,
    createSubCompanyZoneStatus: REQUEST_STATUS.INIT,
    updateSubCompanyZoneUsingKmlStatus: REQUEST_STATUS.INIT,
    deleteZoneStatus: REQUEST_STATUS.INIT
};

export const subCompanyZoneReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getSubCompanyZone, (state) => {
            state.subCompanyZoneStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getSubCompanyZoneSuccess, (state, action) => {
            state.polygon = action.payload || [];
            state.subCompanyZoneStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getSubCompanyZoneFailure, (state) => {
            state.subCompanyZoneStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.getSubCompanyZoneReset, (state) => {
            state.subCompanyZoneStatus = REQUEST_STATUS.INIT;
            state.polygon = [];
        })
        .addCase(actions.createSubCompanyZone, (state) => {
            state.createSubCompanyZoneStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createSubCompanyZoneSuccess, (state) => {
            state.createSubCompanyZoneStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createSubCompanyZoneFailure, (state) => {
            state.createSubCompanyZoneStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createSubCompanyZoneReset, (state) => {
            state.createSubCompanyZoneStatus = REQUEST_STATUS.INIT;
            state.polygon = [];
        })
        .addCase(actions.updateSubCompanyZoneUsingKml, (state) => {
            state.updateSubCompanyZoneUsingKmlStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateSubCompanyZoneUsingKmlSuccess, (state, action) => {
            state.updateSubCompanyZoneUsingKmlStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateSubCompanyZoneUsingKmlFailure, (state) => {
            state.updateSubCompanyZoneUsingKmlStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateSubCompanyZoneUsingKmlReset, (state) => {
            state.updateSubCompanyZoneUsingKmlStatus = REQUEST_STATUS.INIT;
            state.polygon = [];
        })

        .addCase(actions.deleteSubCompanyZone, (state) => {
            state.deleteZoneStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.deleteSubCompanyZoneSuccess, (state, { payload }) => {
            state.polygon = state.polygon.filter((item) => item.subCompanyZoneId !== payload.subCompanyZoneId);
            state.deleteZoneStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.deleteSubCompanyZoneFailure, (state) => {
            state.deleteZoneStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteSubCompanyZoneStatusReset, (state) => {
            state.deleteZoneStatus = REQUEST_STATUS.INIT;
        })
);
