import { getContext, put, takeLatest, select, race, take } from 'redux-saga/effects';
import { get, isEmpty, keyBy, pick } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS, FC_SORT_ORDER, DEFAULT_PER_PAGE } from 'constants/index';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

const commonRequestParams = {
    sortOrder: FC_SORT_ORDER.DESC
};

// WATCHER
export function* orderSaga() {
    yield takeLatest(actions.getOrder, getOrder);
    yield takeLatest(actions.getOrderList, getOrderList);
    yield takeLatest(actions.getOrderListPage, getOrderListPage);
    yield takeLatest(actions.setCouriersFilters, handleSetFilters);
    yield takeLatest(actions.toggleConnectedOnlyCourierFilter, handleSetFilters);
}

function* getOrder({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_ORDER_LIST, { orderId: [payload] });

        const order = get(res, 'body.order[0]', {});

        yield put(actions.getOrderSuccess(order));
    } catch (err) {
        console.error(err);
        yield put(actions.getOrderFailure());
    }
}

function* getOrderList({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        // if (!isArray(payload?.courierId) || isEmpty(payload?.courierId)) {
        //     yield put(actions.getOrderListFailure('It is impossible to request order list for empty courierId list'));
        //     console.log('GetOrderList');
        //     return;
        // }

        const specificRequestParams = pick(payload, 'courierId');
        const companyId = pick(payload, 'companyId');
        const page = get(payload, 'page', 1);
        const perPage = get(payload, 'perPage', DEFAULT_PER_PAGE);

        const res = yield fastCityService.request(FC_REQUESTS.GET_ORDER_LIST, {
            ...commonRequestParams,
            ...specificRequestParams,
            ...companyId,
            limit: perPage,
            offset: (page - 1) * perPage
        });

        const orders = get(res, 'body.order', []);
        const totalOrders = get(res, 'body.totalOrders', 0);

        yield put(actions.getOrderListSuccess({ orders, totalOrders, page, perPage }));
    } catch (err) {
        console.error(err);
        yield put(actions.getOrderListFailure(`${err}`));
    }
}

function* getOrderListPage({ payload }) {
    const filters = yield select(selectors.couriersFilters);

    const page = get(payload, 'page', 1);
    const perPage = get(payload, 'perPage', DEFAULT_PER_PAGE);

    if (filters.courierId) {
        yield put(actions.getOrderList({ courierId: [filters.courierId], page }));
        return;
    }

    let currentFilteredCouriers = yield select(selectors.couriersSelectors.filteredCourierList);
    let currentFilteredCouriersIds = currentFilteredCouriers.map((c) => c.courierId);

    if (isEmpty(currentFilteredCouriersIds)) {
        yield put(actions.getCourierList(pick(filters, ['companyId', 'externalSubCompanyId'])));
        yield race([take(actions.getCourierListSuccess), take(actions.getCourierListFailure)]);

        currentFilteredCouriers = yield select(selectors.couriersSelectors.filteredCourierList);
        currentFilteredCouriersIds = currentFilteredCouriers.map((c) => c.courierId);
    }

    yield put(
        actions.getOrderList({ courierId: currentFilteredCouriersIds, companyId: filters.companyId, page, perPage })
    );
}

function* handleSetFilters() {
    yield put(actions.getOrderListReset());
    yield put(actions.getOrderListPage({ page: 1 }));
}

export function* handleChangedOrderIdList(msg) {
    const logger = yield getLoggerFromContext('Order saga');
    try {
        const filters = yield select(selectors.couriersSelectors.couriersFilters);

        if (!filters.companyId || !filters.externalSubCompanyId) {
            return;
        }

        const subCompany = yield select((state) =>
            selectors.subCompaniesSelectors.entitiesByCompanyId(state, filters.companyId)
        );

        const subCompanyObj = keyBy(subCompany, 'subCompanyId');

        if (
            msg?.body?.companyId !== filters.companyId ||
            subCompanyObj[msg?.body?.subCompanyId ?? '']?.externalSubCompanyId !== filters.externalSubCompanyId
        ) {
            return;
        }

        // Update current order list page
        const { page, perPage } = yield select(selectors.ordersSelectors.pagination);
        yield put(actions.getOrderListPage({ page, perPage }));

        // TODO: optimize it
    } catch (err) {
        logger.error('Handle change order id list message error: %O', err);
    }
}
