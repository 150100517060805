import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// project imports
//import MainLayout from 'layout/MainLayout';
//import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CompanyAdminGuard from 'utils/route-guard/CompanyAdminGuard';
import { routes } from 'constants/index';

// sample page routing
const CurrentCompany = Loadable(lazy(() => import('views/pages/CurrentCompany')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: routes.root,
    element: (
        <AuthGuard>
            <CompanyAdminGuard>
                <Outlet />
            </CompanyAdminGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: routes.currentCompany,
            element: <CurrentCompany />
        }
    ]
};

export default MainRoutes;
