// material-ui
import { Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { isSuperAdmin, isCompanyAdmin } = useAuth();

    const navItems = menuItem.items
        .filter((item) => (!isSuperAdmin ? item.id !== 'admin' : true))
        .filter((item) => (!isCompanyAdmin ? item.id !== 'companyAdmin' : true))
        .map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant='h6' color='error' align='center'>
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return <>{navItems}</>;
};

export default MenuList;
