import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions';

export const useCustomization = () => {
    const dispatch = useDispatch();

    const customization = useSelector((state) => state.customization);

    const setLocale = useCallback((lng) => dispatch({ type: actionTypes.THEME_LOCALE, locale: lng }), [dispatch]);

    return {
        customization,
        setLocale
    };
};
