import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    serverVersion: null,
    serverVersionStatus: REQUEST_STATUS.INIT
};

export const serverVersionReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getServerVersionSuccess, (state, action) => {
            state.serverVersion = action.payload || null;
            state.serverVersionStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getServerVersionFailure, (state) => {
            state.availableOrderStatusesStatus = REQUEST_STATUS.FAILURE;
        })
);
