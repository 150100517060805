import { put, call, takeEvery, all } from 'redux-saga/effects';
import { getLoggerFromContext } from './getLoggerFromContextSaga';

export function* WSEventsToActionsDispatch(createEventChannel, eventsToActionsMap) {
    const logger = yield getLoggerFromContext('EventsToActionsDispatchSaga');

    const callers = eventsToActionsMap.map((eventToAction) =>
        call(runDispatch, createEventChannel, eventToAction, logger)
    );

    yield all(callers);
}

function* runDispatch(createEventChannel, eventToAction, logger) {
    const { event, actionCreator } = eventToAction;
    const action = actionCreator.toString();

    try {
        const eventChannel = yield call(createEventChannel, event);

        yield takeEvery(eventChannel, function* (...args) {
            yield put(actionCreator(...args));
        });

        logger.log('Dispatcher for WS-event: %s to redux-action: %s run', event, action);
    } catch (error) {
        logger.error('WS events (event: %s) to actions (action: %s) dispatcher error: %O', event, action, error);
    }
}
