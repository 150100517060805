import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const defaultOptions = {
    type: SNACKBAR_OPEN,
    open: true,
    variant: 'alert',
    alertSeverity: 'success',
    message: ''
};

export const useSnackBar = () => {
    const dispatch = useDispatch();

    const snackBarOpen = useCallback(
        (options) =>
            dispatch({
                ...defaultOptions,
                ...options
            }),
        [dispatch]
    );

    return snackBarOpen;
};
