export const routes = {
    root: '/',
    login: '/login',
    activate: '/activate',
    couriers: '/couriers',
    couriersEdit: '/couriers/edit',
    couriersDetails: '/couriers/:courierId',
    polygonDetails: '/polygon/:subCompanyId',
    statistics: '/statistics',
    orders: '/orders',
    orderDetails: '/orders/:orderId',
    map: '/map',
    currentCompany: '/current-company',
    admin: '/admin',
    adminCompanies: '/admin/companies',
    adminCompaniesAdd: '/admin/companies/add',
    adminCompaniesDetails: '/admin/companies/:companyId',
    adminRestaurants: '/admin/restaurants',
    passwordReset: '/password-reset'
};
