import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const couriersAdapter = createEntityAdapter({
    selectId: (courier) => courier.courierId,
    sortComparer: (a, b) => a - b
});

const initialState = {
    list: couriersAdapter.getInitialState(),
    listStatus: REQUEST_STATUS.INIT,
    createStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT
};

export const couriersReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getCourierList, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getCourierListSuccess, (state, action) => {
            state.list = couriersAdapter.setMany(state.list, action.payload);
            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getCourierListFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createCourier, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createCourierSuccess, (state, action) => {
            state.list = couriersAdapter.addOne(state.list, action.payload);
            state.createStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createCourierFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createCourierReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.updateCourier, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateCourierSuccess, (state, action) => {
            state.list = couriersAdapter.upsertOne(state.list, action.payload);
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateCourierFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateCourierReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
);
