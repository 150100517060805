import { assign, isNull, get, noop } from 'lodash';
import { isEmptyString } from './helpers/isEmptyString';
import { EventEmitter } from './EventEmitter';
import { checkIsEqualObjStructure } from './helpers/checkIsEqualObjectStructure';

const CLOSE_NORMAL_CODE = 1000;
const ARRAYBUFFER = 'arraybuffer';

const defaultOptions = {
    name: '',
    url: '',
    protocolVersion: '',
    protocolEncoder: null,
    protocolDecoder: null,
    reconnectInterval: 5000,
    maxReconnectInterval: 30000,
    reconnectDecay: 1.5,
    maxReconnectAttempts: null
};

export class WebsocketService extends EventEmitter {
    log = noop;

    logError = noop;

    constructor(customOptions = {}) {
        super();
        this.options = assign({}, defaultOptions, customOptions);
        const { name, url, protocolVersion, protocolEncoder, protocolDecoder } = this.options;

        this.name = name.toUpperCase();
        this.protocolVersion = protocolVersion;
        this.url = `${url}/`;
        this.encoder = protocolEncoder;
        this.decoder = protocolDecoder;

        if (customOptions.loggerProvider) {
            this.log = customOptions.loggerProvider(`Service:${this.name}`);
            this.logError = customOptions.loggerProvider(`Error:Service:${this.name}`);
        }

        this.ws = null;
        this.promiseMap = new Map();

        this.lastRequestId = 0;
        this.reconnectAttempts = 0;
        this.isReconnect = false;
        this.forcedClose = false;
        this.timedOut = false;
        this.timeoutId = null;

        this.WSEvent = {
            Open: `${this.name}:OPEN`,
            Close: `${this.name}:CLOSE`,
            Connecting: `${this.name}:CONNECTING`,
            Reconnecting: `${this.name}:RECONNECTING`,
            ReconnectNotAllowed: `${this.name}:RECONNECT_NOT_ALLOWED`,
            Message: `${this.name}:MESSAGE`,
            Error: `${this.name}:ERROR`
        };
    }

    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
     * WebSocket === window.WebSocket
     */
    get isOpening() {
        return Boolean(this.ws && this.ws.readyState === WebSocket.CONNECTING);
    }

    get isOpened() {
        return Boolean(this.ws && this.ws.readyState === WebSocket.OPEN);
    }

    get isClosing() {
        return Boolean(this.ws && this.ws.readyState === WebSocket.CLOSING);
    }

    get isClosed() {
        return Boolean(!this.ws || this.ws.readyState === WebSocket.CLOSED);
    }

    toString() {
        return this.name;
    }

    connect = () => {
        try {
            if (this.isOpened || this.isOpening) {
                this.logError('The connection is already open (or opening), but trying to open again!');
                return;
            }

            if (isEmptyString(this.protocolVersion)) {
                this.ws = new WebSocket(this.url);
            } else {
                this.ws = new WebSocket(this.url, this.protocolVersion);
            }

            this.ws.binaryType = ARRAYBUFFER;

            this.emit(this.WSEvent.Connecting);

            this.log(
                'Attempting to connect. %o. The current reconnect attempt number: %o',
                this.url,
                this.reconnectAttempts
            );

            this.ws.onopen = this.onopen;
            this.ws.onclose = this.onclose;
            this.ws.onmessage = this.onmessage;
            this.ws.onerror = this.onerror;
        } catch (err) {
            this.onerror(err);
        }
    };

    disconnect = (code = CLOSE_NORMAL_CODE, reason) => {
        if (code === CLOSE_NORMAL_CODE) {
            this.forcedClose = true;
        }

        if (this.isClosed || this.isClosing) {
            this.logError('The connection is already closed (or closing), but trying to close again!');
            return;
        }

        this.ws.close(code, reason);
        this.rejectAllPromises(new Error('WS Connection has been closed'));
    };

    request = (method, data) => {
        this.lastRequestId += 1;

        const rawData = {
            requestId: this.lastRequestId,
            [method]: data
        };

        const preparedData = this.encoder.fromObject(rawData);

        if (!this.isValidInputData(rawData, preparedData) || !this.isValidRequest(preparedData)) {
            throw new Error(`${this.name}: Request content is not valid`);
        }

        this.log('REQUEST:#%o:%o %O', preparedData.requestId, this.getRequestLogName(preparedData), preparedData);
        return this.sendWithPromise(preparedData);
    };

    send = (data) => {
        if (this.isOpened && this.ws) {
            this.ws.send(data);
            return;
        }

        throw new Error(`${this.name}:INVALID_STATE_ERR: Pausing to reconnect WebSocket`);
    };

    onmessage = (evt) => {
        try {
            const message = this.decoder.decode(new Uint8Array(evt.data));
            const messageKey = message.body || '';

            this.log('MESSAGE:#%o:%o %O', message.requestId, this.getMessageLogName(message), get(message, messageKey));

            const messageHandled = this.decoder.toObject(message);
            const messageBody = messageHandled[messageKey];

            this.emit(this.WSEvent.Message, { requestId: messageHandled.requestId, body: messageBody });
            this.emit(messageKey, { requestId: messageHandled.requestId, body: messageBody });

            const currentMessagePromise = this.promiseMap.get(message.requestId);
            this.promiseMap.delete(message.requestId);

            if (!currentMessagePromise) {
                return;
            }

            const code = get(messageBody, 'code', 0);
            // SUCCESS = 0
            // FAIL_INVALID_REQUEST = 1

            if (messageKey === 'result' && code) {
                const description = get(messageBody, 'description', '');
                currentMessagePromise.reject({ code, description });
                return;
            }

            currentMessagePromise.resolve({ requestId: messageHandled.requestId, body: messageBody });
        } catch (err) {
            this.onerror(err);
        }
    };

    onopen = () => {
        this.log('Connection is opened: %o', this.url);

        this.cancelTimeout();
        this.reconnectAttempts = 0;
        this.emit(this.WSEvent.Open, { isReconnect: this.isReconnect });
        this.isReconnect = false;
    };

    onclose = (evt) => {
        this.cancelTimeout();
        this.isReconnect = false;
        this.ws = null;

        const { code, reason, wasClean } = evt;
        this.emit(this.WSEvent.Close, { code, reason, wasClean });

        this.log('Connection is closed. Code: %o. Reason: %o. wasClean: %o.', code, reason, wasClean);

        this.reconnect();
    };

    onerror = (err) => {
        this.logError('Error occurred. %O', err);
        this.emit(this.WSEvent.Error, err);
        this.rejectAllPromises(err);
    };

    sendWithPromise = (request) =>
        new Promise((resolve, reject) => {
            if (!this.isOpened) {
                this.logError('Connection not open!');
                throw new Error(`${this.name}: Connection not open!`);
            }

            this.promiseMap.set(request.requestId, { resolve, reject });

            this.send(this.encoder.encode(request).finish());
        });

    rejectAllPromises = (reason) => {
        this.promiseMap.forEach(({ reject }) => reject(reason));
        this.promiseMap.clear();
    };

    isValidRequest = (request) => {
        const verifiedMessage = this.encoder.verify(request);

        if (!isNull(verifiedMessage)) {
            this.logError('INVALID REQUEST MESSAGE:#%o %O', request.requestId, verifiedMessage);
            return false;
        }

        return true;
    };

    isValidInputData = (rawData, preparedData) => {
        const wrongKeys = checkIsEqualObjStructure(rawData, preparedData);

        if (!isNull(wrongKeys)) {
            this.logError('INVALID REQUEST INPUT DATA: %O', wrongKeys);
            return false;
        }

        return true;
    };

    getMessageLogName = (message) => {
        const messageBodyName = message.body;
        return get(message, `${messageBodyName}.response`, messageBodyName);
    };

    getRequestLogName = (request) => {
        const requestBodyName = request.body;
        const requestName = get(request, `${requestBodyName}.request`, null);
        const entityName = get(request, `${requestBodyName}.entity`, null);
        return requestName || entityName || requestBodyName;
    };

    reconnect = () => {
        if (!this.canReconnect()) {
            this.emit(this.WSEvent.ReconnectNotAllowed);
            this.rejectAllPromises(new Error('WS Connection has been closed'));
            return;
        }

        this.timeoutId = setTimeout(() => {
            this.reconnectAttempts += 1;
            this.isReconnect = true;
            this.emit(this.WSEvent.Reconnecting);

            this.log('Trying to reconnect. Reconnect attempts %o.', this.reconnectAttempts);

            this.connect();
        }, this.calcTimeout());
    };

    canReconnect = () => {
        const { maxReconnectAttempts } = this.options;

        const hasNegativeReason = this.forcedClose || maxReconnectAttempts === 0 || this.isReconnect; // || this.timedOut;
        const hasPositiveReason =
            isNull(maxReconnectAttempts) || this.reconnectAttempts <= Number(maxReconnectAttempts);

        const canReconnect = !hasNegativeReason && hasPositiveReason;

        this.log(
            'Reconnect reasons: %O, can reconnect? %o',
            {
                ForcedClose: this.forcedClose,
                isReconnect: this.isReconnect,
                timedOut: this.timedOut,
                MaxReconnectAttempts: maxReconnectAttempts,
                reconnectAttempts: this.reconnectAttempts
            },
            canReconnect
        );

        return canReconnect;
    };

    calcTimeout = () => {
        const { reconnectInterval, maxReconnectInterval, reconnectDecay } = this.options;
        // eslint-disable-next-line no-restricted-properties
        const timeout = reconnectInterval * Math.pow(reconnectDecay, this.reconnectAttempts);
        return timeout > maxReconnectInterval ? maxReconnectInterval : timeout;
    };

    cancelTimeout = () => {
        if (!isNull(this.timeoutId)) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = null;
    };
}
