import { getContext, put, takeLatest } from 'redux-saga/effects';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { get } from 'lodash';

// WATCHER
export function* externalSubCompaniesSaga() {
    yield takeLatest(actions.getExternalSubCompanies, getExternalSubCompanies);
}

export function* getExternalSubCompanies({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_SUB_COMPANIES_FROM_EXTERNAL, payload);

        const externalSubCompanies = get(res, 'body.subCompany', []).map((company) => ({
            ...company,
            address: new TextDecoder().decode(company.address)
        }));

        yield put(actions.getExternalSubCompaniesSuccess(externalSubCompanies));
    } catch (err) {
        console.error(err);
        yield put(actions.getExternalSubCompaniesFailure());
    }
}
