// third-party
import { FormattedMessage } from 'react-intl';

// project import
import { routes } from 'constants/index';

// assets
import { IconHelp, IconToolsKitchen, IconRun, IconMap2, IconReceipt, IconChartAreaLine } from '@tabler/icons';

// constant
const icons = {
    IconToolsKitchen,
    IconHelp,
    IconRun,
    IconMap2,
    IconReceipt,
    IconChartAreaLine
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'management',
    type: 'group',
    children: [
        // {
        //     id: 'map',
        //     title: <FormattedMessage id='map' />,
        //     type: 'item',
        //     url: routes.map,
        //     icon: icons.IconMap2,
        //     breadcrumbs: false
        // },
        {
            id: 'orders',
            title: <FormattedMessage id='orders' />,
            type: 'item',
            url: routes.orders,
            icon: icons.IconReceipt,
            breadcrumbs: false
        },
        {
            id: 'couriers',
            title: <FormattedMessage id='couriers' />,
            type: 'item',
            url: routes.couriers,
            icon: icons.IconRun,
            breadcrumbs: false
        },
        {
            id: 'statistics',
            title: <FormattedMessage id='statistics' />,
            type: 'item',
            url: routes.statistics,
            icon: icons.IconChartAreaLine,
            breadcrumbs: false
        }
    ]
};

export default other;
