import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'store/selectors';
import { useCouriersFilters } from './useCouriersFilters';

export const useSubCompanyOptions = (companyId = 0) => {
    const { filters } = useCouriersFilters();

    const list = useSelector((state) =>
        selectors.subCompaniesSelectors.entitiesByCompanyId(state, companyId || filters.companyId)
    );
    const listStatus = useSelector(selectors.subCompaniesSelectors.listStatus);

    const options = useMemo(
        () =>
            list
                .filter((rest) => !rest.isDeleted)
                .map((e) => ({
                    label: e.address === '' ? [e.externalSubCompanyId] : [e.address].filter((s) => s).join('/'),
                    ...e
                })),
        [list]
    );
    const isPending = useMemo(() => listStatus.isPending, [listStatus]);

    return { options, isPending };
};
