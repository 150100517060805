import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const subCompaniesAdapter = createEntityAdapter({
    selectId: (sc) => `${sc.companyId}_${sc.externalSubCompanyId}`,
    sortComparer: (a, b) => a?.externalSubCompanyId?.localeCompare(b?.externalSubCompanyId)
});

const initialState = {
    list: subCompaniesAdapter.getInitialState(),
    listStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT
};

export const subCompaniesReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getSubCompanyList, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getSubCompanyListSuccess, (state, { payload: subCompanies }) => {
            state.list = subCompaniesAdapter.setMany(state.list, subCompanies);
            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getSubCompanyListFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateSubCompany, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateSubCompanySuccess, (state, { payload: subCompany }) => {
            state.updateStatus = REQUEST_STATUS.SUCCESS;
            state.list = subCompaniesAdapter.setOne(state.list, subCompany);
        })
        .addCase(actions.updateSubCompanyFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateSubCompanyReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.createSubCompany, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createSubCompanySuccess, (state, { payload: subCompany }) => {
            state.createStatus = REQUEST_STATUS.SUCCESS;
            state.list = subCompaniesAdapter.upsertOne(state.list, subCompany);
        })
        .addCase(actions.createSubCompanyFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createSubCompanyReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.deleteSubCompany, (state) => {
            state.deleteStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.deleteSubCompanySuccess, (state, { payload: subCompany }) => {
            state.deleteStatus = REQUEST_STATUS.SUCCESS;
            state.list = subCompaniesAdapter.upsertOne(state.list, subCompany);
        })
        .addCase(actions.deleteSubCompanyFailure, (state) => {
            state.deleteStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteSubCompanyReset, (state) => {
            state.deleteStatus = REQUEST_STATUS.INIT;
        })
);
