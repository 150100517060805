import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { Grid, Autocomplete, TextField, Switch, Typography } from '@mui/material';

// project imports
import { useAuth } from 'hooks/useAuth';
import { useCompaniesOptions } from 'hooks/useCompaniesOptions';
import { useSubCompanyOptions } from 'hooks/useSubCompanyOptions';
import { useFilteredCourierList } from 'hooks/useFilteredCourierList';
import { useCouriersFilters } from 'hooks/useCouriersFilters';
import { useCouriersOptions } from 'hooks/useCouriersOptions';
import { useParams, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { routes } from 'constants/index';

const CouriersFiltersCardHeader = () => {
    const { isSuperAdmin, isCompanyAdmin } = useAuth();
    const { companyId } = useParams();
    const location = useLocation();

    const isShowFiltersAdmin = useMemo(() => location.pathname !== routes.adminCompanies, [location.pathname]);

    const {
        filters,
        setCompanyFilter,
        setSubCompanyFilter,
        setCourierFilter,
        toggleConnectedOnly
    } = useCouriersFilters();

    const { list, listStatus } = useFilteredCourierList();

    const { options: companyOptions } = useCompaniesOptions();
    const {
        options: externalSubCompanyOptions,
        isPending: isPendingExternalSubCompanyOptions
    } = useSubCompanyOptions();

    const { options: couriersSearchOptions, isPending: isPendingCouriersSearchOptions } = useCouriersOptions();

    const disabled = listStatus.isPending || isPendingExternalSubCompanyOptions || isPendingCouriersSearchOptions;

    return (
        <Grid
            container
            justifyContent='space-between'
            alignItems={{ xs: 'stretch', md: 'center' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            sx={{
                my: -2
            }}
        >
            <Grid item sx={{ mr: { xs: 0, md: 2 } }}>
                <Grid
                    container
                    spacing={0.5}
                    flexDirection={{ xs: 'row', md: 'column' }}
                    justifyContent='space-between'
                >
                    <Grid item>
                        <Typography variant='subtitle1'>
                            <FormattedMessage id='couriers-count' values={{ count: list.length }} />
                        </Typography>
                    </Grid>
                    <Grid item sx={{ py: { xs: 1, md: 0 } }}>
                        <Grid container alignItems='center'>
                            <Typography variant='subtitle1' color={filters.connectedOnly ? 'text' : 'gray'}>
                                <FormattedMessage id='connected-only' />
                            </Typography>
                            <Switch
                                checked={filters.connectedOnly}
                                onChange={() => toggleConnectedOnly()}
                                name='connectedOnly'
                                size='small'
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {isShowFiltersAdmin && companyId === undefined && (
                <Grid item flexGrow={1}>
                    <Grid container spacing={{ xs: 1, md: 2 }} justifyContent='flex-end'>
                        {isSuperAdmin ? (
                            <Grid item xs xl={4}>
                                <Autocomplete
                                    disableClearable
                                    options={companyOptions}
                                    value={companyOptions.find((o) => o.companyId === filters.companyId) || null}
                                    onChange={(_, value) => setCompanyFilter(value || { companyId: 0 })}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<FormattedMessage id='company' />} />
                                    )}
                                    fullWidth
                                    disabled={disabled}
                                />
                            </Grid>
                        ) : null}
                        {isSuperAdmin || isCompanyAdmin ? (
                            <Grid item xs xl={4}>
                                <Autocomplete
                                    options={externalSubCompanyOptions}
                                    value={
                                        externalSubCompanyOptions.find(
                                            (o) => o.externalSubCompanyId === filters.externalSubCompanyId
                                        ) || null
                                    }
                                    onChange={(_, value) =>
                                        setSubCompanyFilter(value || { externalSubCompanyId: null })
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} label={<FormattedMessage id='restaurant' />} />
                                    )}
                                    fullWidth
                                    disabled={isEmpty(externalSubCompanyOptions) || disabled}
                                />
                            </Grid>
                        ) : null}
                        {isSuperAdmin || isCompanyAdmin ? (
                            <Grid item xs xl={4}>
                                <Autocomplete
                                    options={couriersSearchOptions}
                                    value={couriersSearchOptions.find((o) => o.courierId === filters.courierId) || null}
                                    onChange={(_, value) => setCourierFilter(value || { courierId: null })}
                                    renderInput={(params) => (
                                        <TextField {...params} label={<FormattedMessage id='courier' />} />
                                    )}
                                    fullWidth
                                    disabled={isEmpty(couriersSearchOptions) || disabled}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default CouriersFiltersCardHeader;
