import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    externalCouriers: [],
    status: REQUEST_STATUS.INIT
};

export const externalCouriersReduser = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getExternalCouriers, (state) => {
            state.status = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getExternalCouriersSuccess, (state, action) => {
            state.externalCouriers = action.payload || [];
            state.status = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getExternalCouriersFailure, (state) => {
            state.status = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.resetExternalCouriers, () => initialState)
);
