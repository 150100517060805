export const getProtocolSemanticVersion = (section) => {
    const version = section.ProtocolVersion.Value;

    return {
        major: version.MAJOR,
        minor: version.MINOR,
        patch: version.PATCH,

        toString() {
            return `${this.major}.${this.minor}.${this.patch}`;
        }
    };
};
