// third-party
import { FormattedMessage } from 'react-intl';

// project import
import { routes } from 'constants/index';

// assets
import { IconBuilding } from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const companyAdmin = {
    id: 'companyAdmin',
    type: 'group',
    children: [
        {
            id: 'company',
            title: <FormattedMessage id='company' />,
            type: 'item',
            url: routes.currentCompany,
            icon: IconBuilding,
            breadcrumbs: false
        }
    ]
};

export default companyAdmin;
