import { getContext, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { DEFAULT_BINDED_EXTERNAL_AUTH_TOKEN } from 'store/reducers/bindedExternalAuthTokenReducer';

// WATCHER
export function* bindedExternalAuthTokenSaga() {
    yield takeLatest(actions.getBindedExternalAuthToken, getBindedExternalAuthToken);
    yield takeLatest(actions.bindSubCompanyToExternalAuthToken, bindSubCompanyToExternalAuthToken);
    yield takeLatest(actions.unbindSubCompanyFromExternalAuthToken, unbindSubCompanyFromExternalAuthToken);
}

function* getBindedExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_BINDED_EXTERNAL_AUTH_TOKEN, payload);

        const externalAuthTokenId = get(res, 'body.externalAuthToken.externalAuthTokenId', 0);

        yield put(actions.getBindedExternalAuthTokenSuccess(externalAuthTokenId));
    } catch (err) {
        console.error(err);
        yield put(actions.getBindedExternalAuthTokenFailure());
    }
}

function* bindSubCompanyToExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        yield fastCityService.request(FC_REQUESTS.BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN, payload);

        yield put(actions.bindSubCompanyToExternalAuthTokenSuccess(payload.externalAuthTokenId));
    } catch (err) {
        console.error(err);
        yield put(actions.bindSubCompanyToExternalAuthTokenFailure());
    }
}

function* unbindSubCompanyFromExternalAuthToken({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        yield fastCityService.request(FC_REQUESTS.UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN, payload);

        yield put(actions.unbindSubCompanyFromExternalAuthTokenSuccess(DEFAULT_BINDED_EXTERNAL_AUTH_TOKEN));
    } catch (err) {
        console.error(err);
        yield put(actions.unbindSubCompanyFromExternalAuthTokenFailure());
    }
}
