/* eslint-disable eqeqeq */
import { REQUEST_STATUS } from 'constants/index';

export class RequestStatus {
    constructor(statusName) {
        this.name = statusName;
    }

    toString() {
        return this.name;
    }

    valueOf() {
        return this.name;
    }

    get isInit() {
        return this.name === REQUEST_STATUS.INIT;
    }

    get isSuccess() {
        return this.name === REQUEST_STATUS.SUCCESS;
    }

    get isPending() {
        return this.name === REQUEST_STATUS.PENDING;
    }

    get isFailure() {
        return this.name === REQUEST_STATUS.FAILURE;
    }

    get isEmpty() {
        return this.name === REQUEST_STATUS.EMPTY;
    }
}
