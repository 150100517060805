import { getContext, put, takeLatest } from 'redux-saga/effects';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { get } from 'lodash';

// WATCHER
export function* externalCouriersSaga() {
    yield takeLatest(actions.getExternalCouriers, getExternalCouriers);
}

export function* getExternalCouriers({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_COURIERS_FROM_EXTERNAL, payload);

        const externalCouriers = get(res, 'body.courierFromExternal', []);

        yield put(actions.getExternalCouriersSuccess(externalCouriers));
    } catch (err) {
        console.error(err);
        yield put(actions.getExternalCouriersFailure());
    }
}
