import { getContext } from 'redux-saga/effects';
import { SERVICE_NAMES } from 'constants/index';

const noop = () => {};

export function* getLoggerFromContext(namespace) {
    const loggerProvider = yield getContext(SERVICE_NAMES.LOGGER);

    const log = loggerProvider ? loggerProvider(`${namespace}:`) : noop;
    const error = loggerProvider ? loggerProvider(`Error:${namespace}:`) : noop;

    return { log, error };
}
