export class EventEmitter {
    events = {};

    listenersCount = 0;

    subscribe(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }

        this.events[eventName].push(fn);
        this.listenersCount += 1;
    }

    emit(eventName, payload) {
        const event = this.events[eventName];

        if (event) {
            event.forEach((fn) => {
                fn.call(null, payload);
            });
        }
    }

    removeListener(eventName, fnToRemove) {
        this.events[eventName] = this.events[eventName].filter((fn) => fn !== fnToRemove);
        this.listenersCount -= 1;
    }

    unsubscribeAll() {
        this.events = {};
        this.listenersCount = 0;
    }

    on = this.subscribe;
}
