import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    externalAuthToken: {},
    externalAuthTokenStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT,
    createStatus: REQUEST_STATUS.INIT,
    deleteStatus: REQUEST_STATUS.INIT
};

export const externalAuthTokenReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getExternalAuthTokenList, (state) => {
            state.externalAuthTokenStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getExternalAuthTokenListSuccess, (state, action) => {
            state.externalAuthToken = action.payload || {};
            state.externalAuthTokenStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getExternalAuthTokenListFailure, (state) => {
            state.externalAuthTokenStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createExternalAuthToken, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createExternalAuthTokenSuccess, (state, action) => {
            state.externalAuthToken = action.payload || {};
            state.createStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createExternalAuthTokenFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createExternalAuthTokenReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.updateExternalAuthToken, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateExternalAuthTokenSuccess, (state, action) => {
            state.externalAuthToken = action.payload || {};
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateExternalAuthTokenFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateExternalAuthTokenReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.deleteExternalAuthToken, (state) => {
            state.deleteStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.deleteExternalAuthTokenSuccess, (state, action) => {
            state.externalAuthToken = {};
            state.deleteStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.deleteExternalAuthTokenFailure, (state) => {
            state.deleteStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteExternalAuthTokenReset, (state) => {
            state.deleteStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.resetExternalAuthToken, () => initialState)
);
