import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import * as actions from '../actions';

import { accountReducer } from './accountReducer';
import { companiesReducer } from './companiesReducer';
import { adminsReducer } from './adminsReducer';
import { subCompaniesReducer } from './subCompaniesReducer';
import { couriersReducer } from './couriersReducer';
import { lastLocationsReducer } from './lastLocationsReducer';
import { ordersReducer } from './ordersReducer';
import { couriersFiltersReducer } from './couriersFiltersReducer';
import { deliveryStatReducer } from './deliveryStat';
import { subCompanyZoneReducer } from './subCompanyZoneReducer';
import { availableOrderStatusesReducer } from './availableOrderStatusesReducer';
import { externalAuthTokenReducer } from './externalAuthTokenReducer';
import { bindedExternalAuthTokenReducer } from './bindedExternalAuthTokenReducer';
import { serverVersionReducer } from './serverVersionReducer';
import { orderPageReducer } from './orderPageReducer';
import { externalSubCompaniesReduser } from './externalSubCompaniesReduser';

import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import { externalCouriersReduser } from './externalCouriersReduser';

// ==============================|| COMBINE REDUCER ||============================== //

export const appReducer = combineReducers({
    // LOGIC
    account: accountReducer,
    companies: companiesReducer,
    admins: adminsReducer,
    couriersFilters: couriersFiltersReducer,
    couriers: couriersReducer,
    subCompanies: subCompaniesReducer,
    lastLocations: lastLocationsReducer,
    orders: ordersReducer,
    order: orderPageReducer,
    deliveryStat: deliveryStatReducer,
    subCompanyZone: subCompanyZoneReducer,
    availableOrderStatuses: availableOrderStatusesReducer,
    externalAuthToken: externalAuthTokenReducer,
    bindedExternalAuthToken: bindedExternalAuthTokenReducer,
    serverVersion: serverVersionReducer,
    externalSubCompanies: externalSubCompaniesReduser,
    externalCouriers: externalCouriersReduser,

    // UI
    customization: persistReducer(
        {
            key: 'custom',
            storage,
            keyPrefix: 'fastCity-'
        },
        customizationReducer
    ),
    snackbar: snackbarReducer
});

/**
 * Root reducer for resetting the store
 * Credits to Dan Abramov: https://stackoverflow.com/a/35641992
 **/
export const rootReducer = (state, action) => {
    if (action.type === actions.WSConnectionClose.type) {
        const { account, companies, customization, snackbar } = state;
        state = {
            account,
            companies,
            customization,
            snackbar
        };
    }

    if (action.type === actions.logout.type) {
        const { customization } = state;
        state = {
            customization
        };
    }

    return appReducer(state, action);
};
