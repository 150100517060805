import { put, getContext } from 'redux-saga/effects';
import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { getRequestFailureReason } from 'utils/getRequestFailureReason';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

export function* resetPassword(options) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);
    const logger = yield getLoggerFromContext('Reset password saga');

    try {
        yield fastCityService.request(FC_REQUESTS.RESET_PASSWORD, options);
        yield put(actions.resetPasswordSuccess());
    } catch (err) {
        yield put(actions.resetPasswordFailure(getRequestFailureReason(err?.code)));
        logger.error('Can not reset password: %O', err);
    }
}

export function* activate(options) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);
    const logger = yield getLoggerFromContext('Activate reset password saga');

    try {
        yield fastCityService.request(FC_REQUESTS.ACTIVATE, options);
        yield put(actions.activateSuccess());
    } catch (err) {
        yield put(actions.activateFailure(getRequestFailureReason(err?.code)));
        logger.error('Can not reset password: %O', err);
    }
}
