import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// project imports
//import MainLayout from 'layout/MainLayout';
//import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { routes } from 'constants/index';

// sample page routing
const CourierList = Loadable(lazy(() => import('views/pages/CourierList')));
const CourierDetails = Loadable(lazy(() => import('views/pages/Courier')));
const MapPage = Loadable(lazy(() => import('views/pages/Map')));
const OrderList = Loadable(lazy(() => import('views/pages/OrderList')));
const DeliveryStat = Loadable(lazy(() => import('views/pages/DeliveryStat')));
const PolygonDetails = Loadable(lazy(() => import('views/pages/Polygon')));
const Order = Loadable(lazy(() => import('views/pages/Order')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: routes.root,
    element: (
        <AuthGuard>
            <Outlet />
        </AuthGuard>
    ),
    children: [
        {
            path: routes.map,
            element: <MapPage />
        },
        {
            path: routes.orders,
            element: <OrderList />
        },
        {
            path: routes.orderDetails,
            element: <Order />
        },
        {
            path: routes.couriers,
            element: <CourierList />
        },
        {
            path: routes.polygonDetails,
            element: <PolygonDetails />
        },
        {
            path: routes.couriersDetails,
            element: <CourierDetails />
        },
        {
            path: routes.statistics,
            element: <DeliveryStat />
        },
        {
            path: '*',
            element: <Navigate to={routes.map} />
        }
    ]
};

export default MainRoutes;
