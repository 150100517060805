import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const DEFAULT_BINDED_EXTERNAL_AUTH_TOKEN = 0;

const initialState = {
    bindedExternalAuthToken: DEFAULT_BINDED_EXTERNAL_AUTH_TOKEN,
    bindedExternalAuthTokenStatus: REQUEST_STATUS.INIT,
    bindStatus: REQUEST_STATUS.INIT,
    unbindStatus: REQUEST_STATUS.INIT
};

export const bindedExternalAuthTokenReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getBindedExternalAuthToken, (state) => {
            state.bindedExternalAuthTokenStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getBindedExternalAuthTokenSuccess, (state, action) => {
            state.bindedExternalAuthToken = action.payload;
            state.bindedExternalAuthTokenStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getBindedExternalAuthTokenFailure, (state) => {
            state.bindedExternalAuthTokenStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.bindSubCompanyToExternalAuthToken, (state) => {
            state.bindStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.bindSubCompanyToExternalAuthTokenSuccess, (state, action) => {
            state.bindedExternalAuthToken = action.payload;
            state.bindStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.bindSubCompanyToExternalAuthTokenFailure, (state) => {
            state.bindStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.bindSubCompanyToExternalAuthTokenReset, (state) => {
            state.bindStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.unbindSubCompanyFromExternalAuthToken, (state) => {
            state.unbindStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.unbindSubCompanyFromExternalAuthTokenSuccess, (state, action) => {
            state.bindedExternalAuthToken = action.payload;
            state.unbindStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.unbindSubCompanyFromExternalAuthTokenFailure, (state) => {
            state.unbindStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.unbindSubCompanyFromExternalAuthTokenReset, (state) => {
            state.unbindStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.resetBindedExternalAuthToken, () => initialState)
);
