import other from './other';
import admin from './admin';
import companyAdmin from './companyAdmin';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [admin, companyAdmin, other]
};

export default menuItems;
