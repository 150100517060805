import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const companiesAdapter = createEntityAdapter({
    selectId: (company) => company.companyId,
    sortComparer: (a, b) => a - b
});

const initialState = {
    list: companiesAdapter.getInitialState(),
    listStatus: REQUEST_STATUS.INIT,
    createStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT,
    deleteStatus: REQUEST_STATUS.INIT
};

export const companiesReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getCompanyList, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getCompanyListSuccess, (state, action) => {
            state.list = companiesAdapter.setAll(state.list, action.payload);
            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getCompanyListFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.getCurrentCompany, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getCurrentCompanySuccess, (state, action) => {
            state.list = companiesAdapter.addOne(state.list, action.payload);
            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getCurrentCompanyFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createCompany, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createCompanySuccess, (state, action) => {
            state.list = companiesAdapter.addOne(state.list, action.payload);
            state.createStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createCompanyFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createCompanyReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.updateCompany, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateCompanySuccess, (state, action) => {
            state.list = companiesAdapter.upsertOne(state.list, action.payload);
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateCompanyFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateCompanyReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.deleteCompany, (state) => {
            state.deleteStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.deleteCompanySuccess, (state, action) => {
            state.list = companiesAdapter.upsertOne(state.list, action.payload);
            state.deleteStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.deleteCompanyFailure, (state) => {
            state.deleteStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteCompanyReset, (state) => {
            state.deleteStatus = REQUEST_STATUS.INIT;
        })
);
