import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { REQUEST_FAILURE_REASON, routes } from 'constants/index';
import { useQuery } from 'hooks/useQuery';
import { useAuth } from 'hooks/useAuth';
import { useSnackBar } from 'hooks/useSnackBar';
import { setLocalStorage } from 'utils/localStorage/setLocalStorage';
import { LAST_ROUTE_PATH } from 'constants/localStorageKey';

const AUTH_ROUTE = [routes.login, routes.passwordReset];

export const useHandleActivationRoute = () => {
    const location = useLocation();
    const query = useQuery();

    const intl = useIntl();
    const snackBarOpen = useSnackBar();

    const { activate, activateStatus, activateStatusReset, activateFailureReason, isLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn) {
            if (!AUTH_ROUTE.includes(location.pathname)) {
                setLocalStorage(LAST_ROUTE_PATH, location.pathname);
            }
            return;
        }

        if (location.pathname !== routes.activate) {
            return;
        }

        if (!query.has('id') || !query.has('type')) {
            return;
        }

        const id = query.get('id');
        const type = query.get('type');

        if (type !== 'confirm_admin_password_reset') {
            return;
        }

        activate({ activationId: id });
    }, [location, query, activate, isLoggedIn]);

    useEffect(() => {
        if (activateStatus.isFailure) {
            activateStatusReset();
            const id = activateFailureReason || REQUEST_FAILURE_REASON.SERVER;
            snackBarOpen({
                message: intl.formatMessage({ id }),
                alertSeverity: 'error'
            });
        }
    }, [activateFailureReason, activateStatus, activateStatusReset, intl, snackBarOpen]);

    useEffect(() => {
        if (activateStatus.isSuccess) {
            activateStatusReset();
            snackBarOpen({
                message: intl.formatMessage({ id: 'operation-completed-successfully' }),
                alertSeverity: 'success'
            });
        }
    }, [activateStatus, activateStatusReset, intl, snackBarOpen]);
};
