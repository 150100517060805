import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'store/actions';
import { RequestStatus } from 'utils/RequestStatus';
import { isSuperAdmin as isSuperAdminUtility } from 'utils/isSuperAdmin';
import { isCompanyAdmin as isCompanyAdminUtility } from 'utils/isCompanyAdmin';

// ==============================|| AUTH HOOKS ||============================== //

export const useAuth = () => {
    const dispatch = useDispatch();
    const account = useSelector((state) => state.account);

    const login = useCallback((options) => dispatch(actions.login(options)), [dispatch]);
    const logout = useCallback(() => dispatch(actions.logout()), [dispatch]);

    const loginStatus = useMemo(() => new RequestStatus(account.loginStatus), [account.loginStatus]);
    const loginStatusReset = useCallback(() => dispatch(actions.loginReset()), [dispatch]);

    const resetPassword = useCallback((options) => dispatch(actions.resetPassword(options)), [dispatch]);
    const resetPasswordStatus = useMemo(() => new RequestStatus(account.resetPasswordStatus), [
        account.resetPasswordStatus
    ]);
    const resetPasswordStatusReset = useCallback(() => dispatch(actions.resetPasswordReset()), [dispatch]);

    const activate = useCallback((options) => dispatch(actions.activate(options)), [dispatch]);
    const activateStatus = useMemo(() => new RequestStatus(account.activateStatus), [account.activateStatus]);
    const activateStatusReset = useCallback(() => dispatch(actions.activateReset()), [dispatch]);

    // ==============================|| USER ROLES ||============================== //
    const isSuperAdmin = useMemo(() => isSuperAdminUtility(account?.user), [account]);
    const isCompanyAdmin = useMemo(() => isCompanyAdminUtility(account?.user), [account]);

    return {
        ...account,
        login,
        logout,
        loginStatus,
        loginStatusReset,
        resetPassword,
        resetPasswordStatus,
        resetPasswordStatusReset,
        activate,
        activateStatus,
        activateStatusReset,
        isSuperAdmin,
        isCompanyAdmin
    };
};

export default useAuth;
