import { getContext, put, takeLatest, select } from 'redux-saga/effects';
import { get, isArray, isEmpty, intersection, pick } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

// WATCHER
export function* locationSaga() {
    yield takeLatest(actions.getLastLocationList, getLastLocationList);
    yield takeLatest(actions.getCourierListSuccess, updateLastLocationOnGotCourierList);
    yield takeLatest(actions.getOrderSuccess, getLocation);
}

function* getLocation({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    const requestData = {
        ...pick(payload, ['courierId', 'orderId']),
        fromTime: payload.timestampAccept ?? payload.timestampOpen ?? 0,
        toTime: Date.now(),
        offset: 0,
        limit: 0,
        sortOrder: 0
    };

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_LOCATION_LIST, requestData);

        const locations = get(res, 'body.location', []).sort((a, b) => a.timestamp - b.timestamp);

        yield put(actions.getLocationOrderSuccess(locations));
    } catch (err) {
        console.error(err);
    }
}

export function* getLastLocationList({ payload: courierId }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        if (isEmpty(courierId) || !isArray(courierId)) {
            throw new Error('Unable to get last location with courierId parameter: %O', courierId);
        }

        const res = yield fastCityService.request(FC_REQUESTS.GET_LAST_LOCATION_LIST, { courierId });
        const lastLocations = get(res, 'body.location', []);

        yield put(actions.getLastLocationListSuccess(lastLocations));
    } catch (err) {
        yield put(actions.getLastLocationListFailure(`${err}`));
    }
}

export function* handleChangedLastLocation(msg) {
    const logger = yield getLoggerFromContext('Location saga');
    try {
        const lastLocationsCouriersIds = get(msg, 'body.courierId', []);

        const currentFilteredCouriers = yield select(selectors.couriersSelectors.filteredCourierList); // only selected couriers
        const currentFilteredCouriersIds = currentFilteredCouriers.map((c) => c.courierId);
        const courierIdsToRequestLastLocation = intersection(lastLocationsCouriersIds, currentFilteredCouriersIds);

        logger.log('Got changed last location, try to update it: %O', courierIdsToRequestLastLocation);

        if (!isEmpty(courierIdsToRequestLastLocation)) {
            yield put(actions.getLastLocationList(courierIdsToRequestLastLocation));
        }
    } catch (err) {
        logger.error('Handle changed last location message error: %O', err);
    }
}

function* updateLastLocationOnGotCourierList({ payload = [] }) {
    const courierIds = payload.filter((c) => !c.isDeleted && c.isConnected).map((c) => c.courierId);

    if (!isEmpty(courierIds)) {
        yield put(actions.getLastLocationList(courierIds));
    }
}
