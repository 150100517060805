import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import { useCompany } from 'hooks/useCompany';
import { useCurrentSubCompany } from 'hooks/useCurrentSubCompany';

// assets
import { IconLogout } from '@tabler/icons';
import { COOKIE_NAME, deleteCookie } from 'constants/cookie';
import VersionsProject from 'views/common/VersionsProject';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);

    const { logout, user, isSuperAdmin, isCompanyAdmin } = useAuth();

    const company = useCompany(user?.companyId || '');
    const subCompany = useCurrentSubCompany();

    const handleLogout = () => {
        logout();
        deleteCookie(COOKIE_NAME);
    };

    return (
        <Box sx={{ pt: 2, px: 2 }}>
            <Stack sx={{ pb: 1 }} direction='column'>
                <Stack direction='row' spacing={0.5} alignItems='baseline'>
                    <Typography component='span' variant='h5' sx={{ fontWeight: 400 }}>
                        {user?.name}
                    </Typography>
                    {user?.login ? (
                        <Typography variant='subtitle2'>
                            <FormattedMessage id='logged-in-as' values={{ name: user?.login }} />
                        </Typography>
                    ) : null}
                </Stack>
                <Box sx={{ mt: 1 }}>
                    {isSuperAdmin || isCompanyAdmin ? (
                        <Typography
                            component='span'
                            color={isSuperAdmin ? 'secondary' : 'primary'}
                            variant='h5'
                            sx={{ fontWeight: 400 }}
                        >
                            {isSuperAdmin ? (
                                <FormattedMessage id='supervisor' />
                            ) : company?.name ? (
                                <FormattedMessage id='admin-of-company' values={{ company: company.name }} />
                            ) : null}
                        </Typography>
                    ) : (
                        <>
                            <Typography variant='body1' color='primary'>
                                {company?.name}
                            </Typography>
                            <Typography variant='subtitle2'>{subCompany?.address}</Typography>
                        </>
                    )}
                </Box>
                <VersionsProject />
            </Stack>
            <Divider />
            <List
                component='nav'
                sx={{
                    'width': '100%',
                    'maxWidth': 350,
                    'minWidth': 300,
                    'backgroundColor': theme.palette.background.paper,
                    'borderRadius': '10px',
                    [theme.breakpoints.down('md')]: {
                        minWidth: '100%'
                    },
                    '& .MuiListItemButton-root': {
                        mt: 0.5
                    }
                }}
            >
                <ListItemButton sx={{ borderRadius: `${customization.borderRadius}px` }} onClick={handleLogout}>
                    <ListItemIcon>
                        <IconLogout stroke={1.5} size='1.3rem' />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant='body2'>
                                <FormattedMessage id='logout' />
                            </Typography>
                        }
                    />
                </ListItemButton>
            </List>
        </Box>
    );
};

export default ProfileSection;
