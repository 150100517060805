import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    dayList: [],
    dayListStatus: REQUEST_STATUS.INIT
};

export const deliveryStatReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getDeliveryStatDayList, (state) => {
            state.dayListStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getDeliveryStatDayListSuccess, (state, action) => {
            state.dayList = action.payload || [];
            state.dayListStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getDeliveryStatDayListFailure, (state) => {
            state.dayListStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.getDeliveryStatDayListReset, (state) => {
            state.dayListStatus = REQUEST_STATUS.INIT;
            state.dayList = [];
        })
);
