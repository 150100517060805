// material-ui
import { Typography } from '@mui/material';

const Logo = () => (
    <Typography variant='h1' element='span'>
        Fast city
    </Typography>
);

export default Logo;
