import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import { getLastRoutePath } from 'utils/localStorage/getLastRoutePath';

// ==============================|| SUPER ADMIN GUARD ||============================== //

/**
 * Admin guard for routes
 * @param {PropTypes.node} children children element/node
 */
const SuperAdminGuard = ({ children }) => {
    const { isLoggedIn, isSuperAdmin } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (!isSuperAdmin) {
            navigate(getLastRoutePath() ?? config.defaultPath, { replace: true });
        }
    }, [isSuperAdmin, navigate]);

    return children || null;
};

SuperAdminGuard.propTypes = {
    children: PropTypes.node
};

export default SuperAdminGuard;
