import { createReducer } from '@reduxjs/toolkit';
import { get } from 'lodash';

import * as actions from 'store/actions';

const initialState = {
    companyId: 0,
    externalSubCompanyId: null,
    courierId: null,
    connectedOnly: false
};

const filterNames = ['companyId', 'externalSubCompanyId', 'courierId'];

export const couriersFiltersReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.setCouriersFilters, (state, { payload }) => {
            filterNames.forEach((fn) => {
                if (payload.hasOwnProperty(fn)) {
                    state[fn] = get(payload, fn, initialState[fn]);
                }
            });
        })
        .addCase(actions.getCurrentAdminSuccess, (state, action) => {
            state.companyId = get(action.payload, 'companyId', 0);
            state.externalSubCompanyId = get(action.payload, 'externalSubCompanyId', null);
        })
        .addCase(actions.toggleConnectedOnlyCourierFilter, (state) => {
            state.connectedOnly = !state.connectedOnly;
            state.courierId = null;
        })
);
