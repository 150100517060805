import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export const useCouriersFilters = () => {
    const dispatch = useDispatch();

    // FILTERS
    const filters = useSelector(selectors.couriersFilters);
    const setFilters = useCallback((params) => dispatch(actions.setCouriersFilters(params)), [dispatch]);
    const toggleConnectedOnly = useCallback(() => dispatch(actions.toggleConnectedOnlyCourierFilter()), [dispatch]);

    const setCompanyFilter = useCallback(
        ({ companyId }) =>
            filters.companyId !== companyId && setFilters({ companyId, externalSubCompanyId: null, courierId: null }),
        [filters, setFilters]
    );

    const setSubCompanyFilter = useCallback(
        ({ externalSubCompanyId }) => filters.companyId && setFilters({ externalSubCompanyId, courierId: null }),
        [filters, setFilters]
    );

    const setCourierFilter = useCallback(
        ({ courierId }) => filters.companyId && filters.externalSubCompanyId && setFilters({ courierId }),
        [filters, setFilters]
    );

    return {
        filters,
        setFilters,
        setCompanyFilter,
        setSubCompanyFilter,
        setCourierFilter,
        toggleConnectedOnly
    };
};
