import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    externalSubCompanies: [],
    status: REQUEST_STATUS.INIT
};

export const externalSubCompaniesReduser = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getExternalSubCompanies, (state) => {
            state.status = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getExternalSubCompaniesSuccess, (state, action) => {
            state.externalSubCompanies = action.payload || [];
            state.status = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getExternalSubCompaniesFailure, (state) => {
            state.status = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.resetExternalSubCompanies, () => initialState)
);
