import { useTheme } from '@mui/material/styles';
import { Grid, Switch, Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { MENU_TYPE } from 'store/actions';
import { useDispatch } from 'react-redux';

const ThemeModeSwitcher = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const toggleDarkMode = () =>
        dispatch({ type: MENU_TYPE, navType: theme.palette.mode === 'dark' ? 'light' : 'dark' });

    return (
        <Grid
            container
            alignItems='center'
            justifyContent='space-between'
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
                borderRadius: '8px',
                px: 1,
                py: 0.7,
                gap: 1
            }}
        >
            <Grid item>
                <Typography variant='h5' color='primary' sx={{ fontWeight: 400, lineHeight: 1 }}>
                    <FormattedMessage id='dark-mode' />
                </Typography>
            </Grid>
            <Grid item>
                <Switch
                    checked={theme.palette.mode === 'dark'}
                    onChange={toggleDarkMode}
                    name='darkMode'
                    size='small'
                />
            </Grid>
        </Grid>
    );
};

export default ThemeModeSwitcher;
