import { REQUEST_FAILURE_REASON, FC_REQUEST_RESULTS } from 'constants/index';

const FAILURE_REASONS = {
    [FC_REQUEST_RESULTS.INVALID_REQUEST]: REQUEST_FAILURE_REASON.INVALID_REQUEST,
    [FC_REQUEST_RESULTS.SERVER_ERROR]: REQUEST_FAILURE_REASON.SERVER,
    [FC_REQUEST_RESULTS.NO_ACCESS]: REQUEST_FAILURE_REASON.NO_ACCESS,
    [FC_REQUEST_RESULTS.CANNOT_SEND_EMAIL]: REQUEST_FAILURE_REASON.CANNOT_SEND_EMAIL
};

export const getRequestFailureReason = (code) => {
    return (code && FAILURE_REASONS[code]) || REQUEST_FAILURE_REASON.UNKNOWN;
};
