import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'store/selectors';

export const useCouriersOptions = () => {
    const list = useSelector(selectors.couriersSelectors.filteredByCompanyAndExternalSubCompanyAndConnected);
    const listStatus = useSelector(selectors.couriersSelectors.listStatus);

    const options = useMemo(() => list.map((c) => ({ ...c, label: `${c.name} (${c.courierId})` })), [list]);
    const isPending = useMemo(() => listStatus.isPending, [listStatus]);

    return { options, isPending };
};
