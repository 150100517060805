import { put, getContext } from 'redux-saga/effects';
import { SERVICE_NAMES, FC_REQUESTS, REQUEST_FAILURE_REASON, LOGON_RESULTS } from 'constants/index';
import * as actions from 'store/actions';
import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

const FAILURE_REASONS = {
    [LOGON_RESULTS.INACTIVE]: REQUEST_FAILURE_REASON.ADMIN_IS_BLOCKED,
    [LOGON_RESULTS.INCORRECT_LOGIN_PASSWORD]: REQUEST_FAILURE_REASON.CREDENTIALS
};

const getFailureReason = (result) => (result && FAILURE_REASONS[result]) || REQUEST_FAILURE_REASON.CREDENTIALS;

export function* logon(options) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);
    const logger = yield getLoggerFromContext('Logon saga');

    try {
        const result = yield fastCityService.request(FC_REQUESTS.LOGON, {
            ...options
        });

        if (!result?.body?.code) {
            yield put(actions.loginSuccess());
        } else {
            yield put(actions.loginFailure(getFailureReason(result?.body?.code)));
            logger.error('Stop with logon result: %O', result);
        }
    } catch (err) {
        yield put(actions.loginFailure(REQUEST_FAILURE_REASON.SERVER));
        logger.error('Can not login via socket: %O', err);
    }
}
