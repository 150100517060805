import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    availableOrderStatuses: [],
    availableOrderStatusesStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT
};

export const availableOrderStatusesReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getAvailableOrderStatuses, (state) => {
            state.availableOrderStatusesStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getAvailableOrderStatusesSuccess, (state, action) => {
            state.availableOrderStatuses = action.payload || [];
            state.availableOrderStatusesStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getAvailableOrderStatusesFailure, (state) => {
            state.availableOrderStatusesStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateAvailableOrderStatuses, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateAvailableOrderStatusesSuccess, (state, action) => {
            state.availableOrderStatuses = action.payload || [];
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateAvailableOrderStatusesFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.resetUpdateAvailableOrderStatuses, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.resetAvailableOrderStatuses, () => initialState)
);
