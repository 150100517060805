import { getContext, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';

export function* getServerVersion() {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_SERVER_VERSION, {});

        const { major, minor, patch } = get(res, 'body', {});

        yield put(actions.getServerVersionSuccess(`${major}.${minor}.${patch ?? 0}`));
    } catch (err) {
        console.error(err);
        yield put(actions.getServerVersionFailure());
    }
}
