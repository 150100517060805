import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS, DEFAULT_PER_PAGE } from 'constants/index';

export const ordersAdapter = createEntityAdapter({
    selectId: (order) => order.orderId,
    sortComparer: (a, b) => b.timestampOpen - a.timestampOpen
});

const initialState = {
    list: ordersAdapter.getInitialState(),
    listStatus: REQUEST_STATUS.INIT,
    pagination: {
        page: 1,
        perPage: DEFAULT_PER_PAGE,
        total: 0,
        pages: {}
    }
};

export const ordersReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getOrderList, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getOrderListSuccess, (state, action) => {
            state.list = ordersAdapter.setMany(state.list, action.payload.orders);
            state.pagination.total = action.payload.totalOrders;
            state.pagination.page = action.payload.page;
            state.pagination.perPage = action.payload.perPage;
            state.pagination.pages[state.pagination.page] = action.payload.orders.map((o) => o.orderId);

            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getOrderListFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.getOrderListReset, (state) => {
            state.list = initialState.list;
            state.listStatus = initialState.listStatus;
            state.pagination = initialState.pagination;
        })
);
