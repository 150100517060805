import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

export const adminsAdapter = createEntityAdapter({
    selectId: (admin) => admin.login
});

const initialState = {
    list: adminsAdapter.getInitialState(),
    listStatus: REQUEST_STATUS.INIT,
    createStatus: REQUEST_STATUS.INIT,
    updateStatus: REQUEST_STATUS.INIT,
    deleteStatus: REQUEST_STATUS.INIT
};

export const adminsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getAdminList, (state) => {
            state.listStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getAdminListSuccess, (state, { payload }) => {
            state.list = adminsAdapter.upsertMany(state.list, payload);
            state.listStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getAdminListFailure, (state) => {
            state.listStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createAdmin, (state) => {
            state.createStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.createAdminSuccess, (state, action) => {
            state.list = adminsAdapter.addOne(state.list, action.payload);
            state.createStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.createAdminFailure, (state) => {
            state.createStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.createAdminReset, (state) => {
            state.createStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.updateAdmin, (state) => {
            state.updateStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.updateAdminSuccess, (state, action) => {
            state.list = adminsAdapter.upsertOne(state.list, action.payload);
            state.updateStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.updateAdminFailure, (state) => {
            state.updateStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.updateAdminReset, (state) => {
            state.updateStatus = REQUEST_STATUS.INIT;
        })
        .addCase(actions.deleteAdminSuccess, (state, action) => {
            state.list = adminsAdapter.upsertOne(state.list, action.payload);
            state.deleteStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.deleteAdminFailure, (state) => {
            state.deleteStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.deleteAdminReset, (state) => {
            state.deleteStatus = REQUEST_STATUS.INIT;
        })
);
