import { getContext, put, takeLatest } from 'redux-saga/effects';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { get } from 'lodash';

// WATCHER
export function* availableOrderStatusesSaga() {
    yield takeLatest(actions.getAvailableOrderStatuses, getAvailableOrderStatuses);
    yield takeLatest(actions.updateAvailableOrderStatuses, updateAvailableOrderStatuses);
}

export function* getAvailableOrderStatuses({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_AVAILABLE_ORDER_STATUSES, payload);

        const orderStatus = get(res, 'body.orderStatus', []);

        yield put(actions.getAvailableOrderStatusesSuccess(orderStatus));
    } catch (err) {
        console.error(err);
        yield put(actions.getAvailableOrderStatusesFailure());
    }
}

export function* updateAvailableOrderStatuses({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_AVAILABLE_ORDER_STATUSES, {
            ...payload,
            isDeleted: false
        });

        const orderStatus = get(res, 'body.orderStatus', []);

        yield put(actions.updateAvailableOrderStatusesSuccess(orderStatus));
    } catch (err) {
        console.error(err);
        yield put(actions.updateAvailableOrderStatusesFailure());
    }
}
