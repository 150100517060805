import { createReducer } from '@reduxjs/toolkit';

import * as actions from 'store/actions';
import { REQUEST_STATUS } from 'constants/index';

const initialState = {
    order: {},
    orderStatus: REQUEST_STATUS.INIT
};

export const orderPageReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getOrder, (state) => {
            state.orderStatus = REQUEST_STATUS.PENDING;
        })
        .addCase(actions.getOrderSuccess, (state, action) => {
            state.order = action.payload;
            state.orderStatus = REQUEST_STATUS.SUCCESS;
        })
        .addCase(actions.getLocationOrderSuccess, (state, action) => {
            state.order.coordinateCourier = action.payload ?? [];
        })
        .addCase(actions.getOrderFailure, (state) => {
            state.orderStatus = REQUEST_STATUS.FAILURE;
        })
        .addCase(actions.resetOrder, () => initialState)
);
