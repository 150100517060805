import ReactDOM from 'react-dom';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { YMaps } from 'react-yandex-maps';

// project imports
import App from 'App';
import { store, persister } from 'store';

// style + assets
import 'assets/scss/style.scss';
import config from 'config';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
            <BrowserRouter basename={config.basename}>
                <YMaps>
                    <App />
                </YMaps>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
