import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import CouriersFiltersCardHeader from '../../../views/common/CouriersFiltersCardHeader';
import { drawerWidth } from 'constants/index';
import LangSwitcher from 'ui-component/custom/LangSwitcher';
// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: `calc(${drawerWidth}px - 16px)`,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    },
                    [theme.breakpoints.down('sm')]: {
                        alignSelf: 'flex-start',
                        width: 'auto'
                    }
                }}
            >
                <Box component='span' sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant='rounded'
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            'transition': 'all .2s ease-in-out',
                            'background':
                                theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            'color':
                                theme.palette.mode === 'dark'
                                    ? theme.palette.secondary.main
                                    : theme.palette.secondary.dark,
                            '&:hover': {
                                background:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.main
                                        : theme.palette.secondary.dark,
                                color:
                                    theme.palette.mode === 'dark'
                                        ? theme.palette.secondary.light
                                        : theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color='inherit'
                    >
                        <IconMenu2 stroke={1.5} size='1.3rem' />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{ flexGrow: 1, pl: 2, pr: { sm: 2 } }}>
                <CouriersFiltersCardHeader />
            </Box>

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <ProfileSection />
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block', marginLeft: '15px' } }}>
                <LangSwitcher />
            </Box>

            <Box sx={{ display: { xs: 'block', sm: 'none' }, alignSelf: 'flex-start' }}>
                <MobileSection />
            </Box>
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
