const SIX_HOURS_IN_SECONDS = 60 * 60 * 6;

export const MAX_AGE_COOKIE = SIX_HOURS_IN_SECONDS;
export const COOKIE_NAME = 'fastcity_auth_token';

export const getCookie = (name) => {
    let cookie = document.cookie.split('; ').find((row) => row.startsWith(name + '='));
    return cookie ? cookie.split('=')[1] : null;
};

export const deCodeCookie = (array) => {
    const deCodeArray = [...array.slice(Number(array.length / 2)), ...array.slice(0, Number(array.length / 2))];

    return deCodeArray.join('');
};

export const enCodeCookie = (array) => {
    const deCodeArray = [...array.slice(0, Number(array.length / 2)), ...array.slice(Number(array.length / 2))];

    return deCodeArray.join('');
};

export const deleteCookie = (name) => {
    document.cookie = name + '=; Max-Age=-99999999;';
};
