import { pick, get } from 'lodash';
import { getContext, put, takeLatest } from 'redux-saga/effects';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';

// WATCHER
export function* deliveryStatSaga() {
    yield takeLatest(actions.getDeliveryStatDayList, getDeliveryStatDayList);
}

export function* getDeliveryStatDayList({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const timeInterval = pick(payload, ['courierId', 'fromTime', 'toTime']);
        const res = yield fastCityService.request(FC_REQUESTS.GET_DELIVERY_STAT_DAY_LIST, { timeInterval });

        const stat = get(res, 'body.deliveryStatDay', []);
        yield put(actions.getDeliveryStatDayListSuccess(stat));
    } catch (err) {
        yield put(actions.getDeliveryStatDayListFailure(`${err}`));
    }
}
