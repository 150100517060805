import { getContext, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { get, isUndefined, pick } from 'lodash';

import { SERVICE_NAMES, FC_REQUESTS } from 'constants/index';
import * as actions from 'store/actions';
import { isSuperAdmin } from 'utils/isSuperAdmin';
// import { getLoggerFromContext } from '../utils/getLoggerFromContextSaga';

const prepareCompanyData = (data) => {
    return {
        isDeleted: false,
        mapType: isUndefined(data.mapType) ? 0 : data.mapType,
        ...data
    };
};

// WATCHER
export function* companySaga() {
    yield takeEvery(actions.getCurrentAdminSuccess, function* ({ payload }) {
        if (isSuperAdmin(payload)) {
            yield getCompanyList();
        } else {
            yield getCurrentCompany();
        }
    });

    yield takeLatest(actions.getCompanyList, getCompanyList);
    yield takeLatest(actions.createCompany, createCompany);
    yield takeLatest(actions.updateCompany, updateCompany);
    yield takeLatest(actions.deleteCompany, deleteCompany);
}

export function* getCompanyList() {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_COMPANY_LIST, { withDeleted: false });

        const companies = get(res, 'body.company', []);
        const preparedData = companies.map((c) => prepareCompanyData(c));

        yield put(actions.getCompanyListSuccess(preparedData));
    } catch (err) {
        yield put(actions.getCompanyListFailure(`${err}`));
    }
}

export function* createCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const company = pick(payload, [
            'name',
            'description',
            'site',
            'email',
            'supportPhone',
            'mapType',
            'maximumActiveOrdersOnCourier'
        ]);

        const res = yield fastCityService.request(FC_REQUESTS.CREATE_COMPANY, company);
        const preparedData = prepareCompanyData(res.body);

        yield put(actions.createCompanySuccess(preparedData));
    } catch (err) {
        yield put(actions.createCompanyFailure(`${err}`));
    }
}

export function* updateCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const company = pick(payload, [
            'companyId',
            'name',
            'description',
            'site',
            'email',
            'supportPhone',
            'mapType',
            'maximumActiveOrdersOnCourier'
        ]);
        const res = yield fastCityService.request(FC_REQUESTS.UPDATE_COMPANY, company);
        // if mapType is equal to zero, then the field is removed from the object, I force it to be equal to 0
        const mapType = get(res, 'body.mapType', 0);
        const preparedData = prepareCompanyData({ ...res.body, mapType });

        yield put(actions.updateCompanySuccess(preparedData));
    } catch (err) {
        yield put(actions.updateCompanyFailure(`${err}`));
    }
}

export function* getCurrentCompany() {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const res = yield fastCityService.request(FC_REQUESTS.GET_CURRENT_COMPANY, {});
        const preparedData = prepareCompanyData(res.body);

        yield put(actions.getCurrentCompanySuccess(preparedData));
    } catch (err) {
        yield put(actions.getCurrentCompanyFailure(`${err}`));
    }
}

export function* deleteCompany({ payload }) {
    const fastCityService = yield getContext(SERVICE_NAMES.FAST_CITY_API);

    try {
        const company = pick(payload, ['companyId']);
        const res = yield fastCityService.request(FC_REQUESTS.DELETE_COMPANY, company);
        const preparedData = prepareCompanyData(res.body);

        yield put(actions.deleteCompanySuccess(preparedData));
        yield put(actions.getCompanyList());
    } catch (err) {
        yield put(actions.deleteCompanyFailure(`${err}`));
    }
}
