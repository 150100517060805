import { eventChannel, END } from 'redux-saga';

export const eventChannelFactory = (wsService, ...closeChannelEvents) => (event) =>
    eventChannel((emitter) => {
        wsService.on(event, emitter);
        const closeChannel = () => emitter(END);
        closeChannelEvents.forEach((closeEvent) => {
            wsService.on(closeEvent, closeChannel);
        });
        return () => {
            wsService.removeListener(event, emitter);
            closeChannelEvents.forEach((closeEvent) => {
                wsService.removeListener(closeEvent, closeChannel);
            });
        };
    });
