import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import config from 'config';

const VersionsProject = () => {
    const { serverVersion } = useSelector((state) => state.serverVersion);

    return (
        <Typography variant='subtitle2' sx={{ mt: 0.5 }}>{`Ver. ${serverVersion ?? <FormattedMessage id='n/a' />} / ${
            config.appVersion
        }`}</Typography>
    );
};

export default VersionsProject;
