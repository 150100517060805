import { useRoutes } from 'react-router-dom';
import { useHandleActivationRoute } from 'hooks/useHandleActivationRoute';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes';
import CompanyAdminRoutes from './CompanyAdminRoutes';
import NavMotion from 'layout/NavMotion';
import MainLayout from 'layout/MainLayout';

const LoggedInRoutes = {
    element: (
        <NavMotion>
            <MainLayout />
        </NavMotion>
    ),
    children: [AdminRoutes, CompanyAdminRoutes, MainRoutes]
};

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // handle routes.activate route
    useHandleActivationRoute();

    return useRoutes([LoggedInRoutes, LoginRoutes]);
}
