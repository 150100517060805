import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'store/selectors';

export const useCompaniesOptions = () => {
    const list = useSelector(selectors.companiesSelectors.selectAll);
    const listStatus = useSelector(selectors.companiesSelectors.listStatus);

    const options = useMemo(
        () => list.filter((c) => !c.isDeleted).map((c) => ({ label: `${c.name} (#${c.companyId})`, ...c })),
        [list]
    );
    const isPending = useMemo(() => listStatus.isPending, [listStatus]);

    return { options, isPending };
};
