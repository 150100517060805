import { fastcity } from 'protocol/lib/bundle.pb';

export const SERVICE_NAMES = {
    FAST_CITY_API: 'FAST_CITY_API',
    LOGGER: 'LOGGER'
};

export const FC_REQUESTS = {
    LOGON: 'logon',
    RESET_PASSWORD: 'resetPassword',
    ACTIVATE: 'activate',
    GET_CURRENT_ADMIN: 'getCurrentAdmin',
    GET_CURRENT_COMPANY: 'getCurrentCompany',

    GET_COMPANY_LIST: 'getCompanyList',
    CREATE_COMPANY: 'createCompany',
    UPDATE_COMPANY: 'updateCompany',
    DELETE_COMPANY: 'deleteCompany',

    GET_ADMIN_LIST: 'getAdminList',
    CREATE_ADMIN: 'createAdmin',
    UPDATE_ADMIN: 'updateAdmin',
    DELETE_ADMIN: 'deleteAdmin',

    GET_GROUP_LIST: 'getGroupList',
    CREATE_GROUP: 'createGroup',
    UPDATE_GROUP: 'updateGroup',
    DELETE_GROUP: 'deleteGroup',

    GET_SUB_COMPANY_ID_LIST: 'getSubCompanyIdList',

    GET_COURIER_LIST: 'getCourierList',
    CREATE_COURIER: 'createCourier',
    UPDATE_COURIER: 'updateCourier',
    SET_COURIER_GROUP: 'setCourierGroup',
    GET_SUB_COMPANY_ID_TO_EXTERNAL_COURIER_ID_LIST: 'getSubCompanyIdToExternalCourierIdList',
    GET_COURIERS_FROM_EXTERNAL: 'getCouriersFromExternal',
    CREATE_SUB_COMPANY_ID_TO_EXTERNAL_COURIER_ID: 'createSubCompanyIdToExternalCourierId',
    UPDATE_SUB_COMPANY_ID_TO_EXTERNAL_COURIERID: 'updateSubCompanyIdToExternalCourierId',

    GET_LAST_LOCATION_LIST: 'getLastLocationList',
    GET_LOCATION_LIST: 'getLocationList',

    GET_SUB_COMPANY_LIST: 'getSubCompanyList',
    UPDATE_SUB_COMPANY: 'updateSubCompany',
    CREATE_SUB_COMPANY: 'createSubCompany',
    DELETE_SUB_COMPANY: 'deleteSubCompany',

    GET_SUB_COMPANY_ZONE: 'getSubCompanyZoneList',
    CREATE_SUB_COMPANY_ZONE: 'createSubCompanyZoneFromKml',
    UPDATE_SUB_COMPANY_ZONE: 'updateSubCompanyZoneUsingKml',
    UPDATE_SUB_COMPANY_ZONE_WITHOUT_KML: 'updateSubCompanyZone',

    DELETE_SUB_COMPANY_ZONE: 'deleteSubCompanyZone',

    GET_ORDER_LIST: 'getOrderList',

    GET_DELIVERY_STAT_DAY_LIST: 'getDeliveryStatDayList',
    GET_DELIVERY_STAT_LIST: 'getDeliveryStatList',
    GET_WORKING_DAY_LIST: 'getWorkingDayList',

    GET_AVAILABLE_ORDER_STATUSES: 'getAvailableOrderStatuses',
    UPDATE_AVAILABLE_ORDER_STATUSES: 'updateAvailableOrderStatuses',
    GET_AVAILABLE_ORDER_STATUSES_LIST: 'getAvailableOrderStatusesList',

    GET_EXTERNAL_AUTH_TOKEN: 'getExternalAuthToken',
    UPDATE_EXTERNAL_AUTH_TOKEN: 'updateExternalAuthToken',
    CREATE_EXTERNAL_AUTH_TOKEN: 'createExternalAuthToken',
    DELETE_EXTERNAL_AUTH_TOKEN: 'deleteExternalAuthToken',

    BIND_SUB_COMPANY_TO_EXTERNAL_AUTH_TOKEN: 'bindSubCompanyToExternalAuthToken',
    UNBIND_SUB_COMPANY_FROM_EXTERNAL_AUTH_TOKEN: 'unbindSubCompanyFromExternalAuthToken',
    GET_BINDED_EXTERNAL_AUTH_TOKEN: 'getBindedExternalAuthToken',

    GET_SERVER_VERSION: 'getServerVersion',

    GET_SUB_COMPANIES_FROM_EXTERNAL: 'getSubCompaniesFromExternal'
};

export const FC_REQUEST_RESULTS = {
    SUCCESS: fastcity.admin.Result.Code.SUCCESS,
    INVALID_REQUEST: fastcity.admin.Result.Code.INVALID_REQUEST,
    SERVER_ERROR: fastcity.admin.Result.Code.SERVER_ERROR,
    NO_ACCESS: fastcity.admin.Result.Code.NO_ACCESS,
    CANNOT_SEND_EMAIL: fastcity.admin.Result.Code.CANNOT_SEND_EMAIL
};

export const LOGON_RESULTS = {
    INACTIVE: fastcity.admin.LogonResult.Code.INACTIVE,
    INCORRECT_LOGIN_PASSWORD: fastcity.admin.LogonResult.Code.INCORRECT_LOGIN_PASSWORD,
    SUCCESS: fastcity.admin.LogonResult.Code.SUCCESS
};

export const ORDER_STATUS = {
    UNKNOWN: fastcity.common.Order.Status.S_UNKNOWN,
    IN_PROGRESS: fastcity.common.Order.Status.S_IN_PROGRESS,
    MAKELINE: fastcity.common.Order.Status.S_MAKELINE, // заказ на мэйклайне(в процессе приготовления)
    ROUTING_STATION: fastcity.common.Order.Status.S_ROUTING_STATION, // заказ приготовлен
    OUT_THE_DOOR: fastcity.common.Order.Status.S_OUT_THE_DOOR, // заказ покинул ресторан,курьер выехал к клиенту
    COMPLETE: fastcity.common.Order.Status.S_COMPLETE,
    GIFT: fastcity.common.Order.Status.S_GIFT,
    BAD: fastcity.common.Order.Status.S_BAD, // заказ отменен с затратой продуктов
    VOID: fastcity.common.Order.Status.S_VOID, // заказ отменен без затраты продуктов
    BEING_TAKEN: fastcity.common.Order.Status.S_BEING_TAKEN,
    ABANDONED: fastcity.common.Order.Status.S_ABANDONED,
    SUSPENDED: fastcity.common.Order.Status.S_SUSPENDED,
    GIFT_CARD_PURCHASE: fastcity.common.Order.Status.S_GIFT_CARD_PURCHASE,
    GIFT_CARD_VOID: fastcity.common.Order.Status.S_GIFT_CARD_VOID,
    TRANSFERRED: fastcity.common.Order.Status.S_TRANSFERRED,
    HISTORIC_ORDER: fastcity.common.Order.Status.S_HISTORIC_ORDER,
    BEING_ASSIGNED: fastcity.common.Order.Status.S_BEING_ASSIGNED
};

export const ORDER_STATUS_TRANSLATION_KEY = {
    [ORDER_STATUS.UNKNOWN]: 'order-status-unknown',
    [ORDER_STATUS.IN_PROGRESS]: 'order-status-in-progress',
    [ORDER_STATUS.MAKELINE]: 'order-status-makeline',
    [ORDER_STATUS.ROUTING_STATION]: 'order-status-routing-station',
    [ORDER_STATUS.OUT_THE_DOOR]: 'order-status-out-the-door',
    [ORDER_STATUS.COMPLETE]: 'order-status-complete',
    [ORDER_STATUS.GIFT]: 'order-status-gift',
    [ORDER_STATUS.BAD]: 'order-status-bad',
    [ORDER_STATUS.VOID]: 'order-status-void',
    [ORDER_STATUS.BEING_TAKEN]: 'order-status-being-taken',
    [ORDER_STATUS.ABANDONED]: 'order-status-abandoned',
    [ORDER_STATUS.SUSPENDED]: 'order-status-suspended',
    [ORDER_STATUS.GIFT_CARD_PURCHASE]: 'order-status-gift-card-purchase',
    [ORDER_STATUS.GIFT_CARD_VOID]: 'order-status-gift-card-void',
    [ORDER_STATUS.TRANSFERRED]: 'order-status-transferred',
    [ORDER_STATUS.HISTORIC_ORDER]: 'order-status-historic-order',
    [ORDER_STATUS.BEING_ASSIGNED]: 'order-status-being-assigned'
};

export const IIKO_ORDER_STATUS_TRANSLATION_KEY = {
    [fastcity.admin.IIKOOrderStatus.UNCOFIRMED]: 'iiko-order-status-uncofirmed',
    [fastcity.admin.IIKOOrderStatus.WAIT_COOKING]: 'iiko-order-status-wait-cooking',
    [fastcity.admin.IIKOOrderStatus.READY_FOR_COOKING]: 'iiko-order-status-ready-for-cooking',
    [fastcity.admin.IIKOOrderStatus.COOKING_STARTED]: 'iiko-order-status-cooking-started',
    [fastcity.admin.IIKOOrderStatus.COOKING_COMPLETE]: 'iiko-order-status-cooking-complete',
    [fastcity.admin.IIKOOrderStatus.WAITING]: 'iiko-order-status-waiting',
    [fastcity.admin.IIKOOrderStatus.ON_WAY]: 'iiko-order-status-on-way',
    [fastcity.admin.IIKOOrderStatus.DELIVERED]: 'iiko-order-status-delivered',
    [fastcity.admin.IIKOOrderStatus.CLOSED]: 'iiko-order-status-closed',
    [fastcity.admin.IIKOOrderStatus.CANCELLED]: 'iiko-order-status-cancelled'
};

export const FC_SORT_ORDER = {
    ASC: fastcity.common.SortOrder.Order.ASC,
    DESC: fastcity.common.SortOrder.Order.DESC
};

export const FC_WS_MSG = {
    ADMIN: 'admin',
    CHANGED_LAST_LOCATION: 'changedLastLocation',
    CHANGED_COURIER_ID_LIST: 'changedCourierIdList',
    CHANGED_ORDER_ID_LIST: 'changedOrderIdList'
};

export const CONNECTION_STATUS = {
    OPENED: 'OPENED',
    CLOSED: 'CLOSED',
    ON_ERROR: 'ON_ERROR'
};

export const REQUEST_STATUS = {
    INIT: 'INIT',
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
};
