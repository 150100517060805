import { createReducer, createEntityAdapter } from '@reduxjs/toolkit';

import * as actions from 'store/actions';

export const lastLocationsAdapter = createEntityAdapter({
    selectId: (lastLocation) => lastLocation.courierId
});

const initialState = lastLocationsAdapter.getInitialState();

export const lastLocationsReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(actions.getLastLocationListSuccess, lastLocationsAdapter.setMany)
        .addCase(actions.logout, (state) => {
            state = initialState;
        })
);
